.refresh-button {
    font-family: inherit;
    font-size: 20px;
    padding: 10px;
    background-color: #464747;
    border-radius: 5px;
    font-weight: 900;
    color: white;
    border: none;
    cursor: pointer;
    transition: all .4s ease;
}

.refresh-button:hover {
    background-color: #191a1b;
    transition: all .4s ease;
}