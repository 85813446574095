.container {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.close-button-wrapper {
    display: flex;
    justify-content: end;
    font-size: 20px;
    cursor: pointer;
}

.title {
    display: flex;
    justify-content: center;
    font-size: 25px;
    font-weight: 800;
    align-items: center;
}

.content {
    display: flex;
    flex-direction: column;
}

/* Topic items */

.topic-item-container {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #e2e2e2;
    border-radius: 5px;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    gap: 5px;
}

.topic-item-checkbox-wrapper {
    display: flex;
    margin: 5px;
    flex: 0 1 10%;
    min-height: 35px;
    justify-content: center;
    align-items: center;
}

.topic-item-checkbox {
    width: 30px;
    height: 15px;
    cursor: pointer;
    text-align: center;
}

.topic-item {
    display: flex;
    align-items: center;
    margin: 5px;
    min-height: 35px;
    flex-basis: 0;
    flex-grow: 1;
}

.action-buttons-wrapper {
    display: flex;
    justify-content: space-between;
}

.select-all-button {
    display: flex;
    padding: 10px;
    border-radius: 10px;
    background-color: #d0d2d4;
    cursor: pointer;
    align-items: center;
}

.download-file-icon {
    font-size: 15px;
}