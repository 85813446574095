.container {
    display: flex;
    height: 100%;
    align-items: center;
    background-color: #363636;
}

.login-wrapper {
    display: flex;
    width: 850px;
    height: 500px;
    align-items: center;
    justify-content: center;
}

.left-side {
    display: flex;
    flex-basis: 30%;
    padding: 0 20px;
    background: #eeeeee;
    box-shadow: 3px 2px 2px rgb(0 0 0 / 0.2);
    border-radius: 10px 0 0 10px;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.right-side {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    flex-basis: 70%;
    background: #eeeeee;
    box-shadow: 3px 2px 2px rgb(0 0 0 / 0.2);
    border-radius: 0 10px 10px 0;
    height: 100%;
    width: 100%;
    justify-content: center;
}

.logo-wrapper {
    width: 250px;
    height: 250px;
}

.welcome-text {
    flex-basis: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
}

.greetings-text {
    font-size: 55px;
}

.button-wrapper {
    flex-basis: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-button {
    color: white;
    font-family: inherit;
    font-size: 15px;
    border: none;
    outline: none;
    background: #3b3b3b;
    padding: 20px;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.5s ease;
}

.login-button:hover {
    background: #2d2d2d;
    padding: 21px;
    font-size: 16.5px;
    transition: all 0.5s ease;
}

@media screen and (max-width: 850px) {
    .greetings-text {
        font-size: 35px;
    }

    .login-wrapper {
        flex-direction: column;
        width: 500px;
        height: 500px;
    }

    .logo-wrapper {
        width: 180px;
        height: 180px;
    }

    .login-button {
        font-size: 12px;
        padding: 10px;
    }

    .login-button:hover {
        font-size: 13px;
        padding: 11px;
    }

    .left-side {
        background: linear-gradient(180deg, #606060 -50%, #e8e8e8);
        border-radius: 10px 10px 0 0;
    }

    .right-side {
        background: linear-gradient(0deg, #f8fcff -50%, #e8e8e8);
        border-radius: 0 0 10px 10px;
    }

    .welcome-text {
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 541px) {
    .greetings-text {
        font-size: 35px;
    }

    .login-wrapper {
        flex-direction: column;
        width: 300px;
        height: 400px;
    }

    .logo-wrapper {
        width: 150px;
        height: 150px;
    }

    .login-button {
        font-size: 12px;
        padding: 10px;
    }

    .left-side {
        background: linear-gradient(180deg, #606060 -50%, #e8e8e8);
        border-radius: 10px 10px 0 0;
    }

    .right-side {
        background: linear-gradient(0deg, #f8fcff -50%, #e8e8e8);
        border-radius: 0 0 10px 10px;
    }

    .welcome-text {
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 345px) {
    .welcome-text {
        font-size: 10px;
    }

    .greetings-text {
        font-size: 25px !important;
    }

    .login-wrapper {
        flex-direction: column;
        width: 200px;
        height: 300px;
    }

    .logo-wrapper {
        width: 100px;
        height: 100px;
    }

    .login-button {
        font-size: 10px;
        padding: 5px;
    }

    .left-side {
        background: linear-gradient(180deg, #606060 -50%, #e8e8e8);
        border-radius: 10px 10px 0 0;
    }

    .right-side {
        background: linear-gradient(0deg, #f8fcff -50%, #e8e8e8);
        border-radius: 0 0 10px 10px;
    }

    .welcome-text {
        align-items: center;
        justify-content: center;
    }
}