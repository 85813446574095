.project-view-container {
    display: grid;
    width: 1256px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "actionsbuttons actionsbuttons"
        "topicbrowser corpusstats"
        "topicoverlaptable topicoverlaptable"
        "unigram bigram"
        "verbatim verbatim";
    grid-gap: 10px;
    margin: 20px;
}

.action-buttons-wrapper {
    grid-area: actionsbuttons;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}

.action-buttons-left-side {
    display: flex;
}

.action-buttons-right-side {
    display: flex;
    gap: 5px;
}

@media only screen and (max-width: 950px) {
    .project-view-container {
        width: 100%;
        grid-template-areas:
            "actionsbuttons"
            "topicbrowser"
            "topicoverlaptable"
            "corpusstats"
            "unigram"
            "bigram"
            "verbatim";
        grid-template-columns: 1fr;
    }
}