.container {
    display: flex;
    margin-top: 15px;
    width: 75%;
    max-width: 1018px;
    flex-direction: column;
    align-items: center;
}

.title-wrapper {
    display: flex;
    height: 2rem;
    justify-content: space-between;
    padding: 0px 0;
    margin: 15px 0 5px 0;
    width: 97%;
    align-items: center;
}

.info-message-wrapper {
    font-size: 14px;
}

.title {
    font-size: 20px;
    font-weight: bold;
}

.action-buttons-wrapper {
    display: flex;
    gap: 10px;
}

.version-overview-container {
    background: white;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 1px 2px rgb(0 0 0 / 0.2);
    margin: 10px;
    padding: 30px 0;
    border-radius: 20px;
}

.refresh-button-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.table-container {
    width: 90%;
    padding: 0 30px;
    min-height: 250px;
    border-radius: 5px;
}

.empty-table-wrapper {
    display: flex;
    flex-direction: column;
    font-family: inherit;
    text-align: center;
    justify-content: center;
    min-height: 250px;
    gap: 15px
}

#version-table {
    border-collapse: collapse;
    width: 100%;
}

#version-table thead tr {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    color: rgb(149 149 149);
    font-weight: bold;
    border-bottom: 1px solid #ddd;
}

#version-table td,
#version-table th,
#version-table tbody tr th {
    padding: 5px;
    text-align: center;
}

#version-table tbody tr th {
    font-weight: 100;
}

.action-button {
    background: none;
    color: black;
    padding: 5px;
    display: flex;
    justify-content: center;
    font-size: 22px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    transition: all .4s ease;
}

.action-button:hover {
    background-color: rgb(220, 220, 220);
    transition: all .4s ease;
}

.dropdown-menu {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    right: 20px;
    display: none;
    border-radius: 10px;
    position: absolute;
    bottom: 30px;
    gap: 2px;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    flex-direction: column;
    align-items: center;
    padding: 15px;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #f1f1f1
}

.dropdown-item {
    color: black;
    cursor: pointer;
    padding: 5px;
    transition: all .3s ease;
    border-radius: 5px;
}

.dropdown-item:hover {
    color: white !important;
    padding: 5px;
    background-color: rgb(62, 62, 62);
    transition: all .3s ease;
}

@media only screen and (max-width: 500px) {
    .container {
        width: 95%;
    }

    #version-table {
        font-size: 11px;
    }
}

@media screen and (max-width: 650px) {
    .info-message-wrapper {
        font-size: 12px;
    }
}

@media screen and (max-width: 725px) {
    #version-table {
        font-size: 10px;
    }
}

@media screen and (max-width: 500px) {
    #version-table {
        font-size: 9px;
    }
}