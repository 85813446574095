.container {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 0.2);
    flex-basis: 0;
    flex-grow: 1;
    min-height: 310px;
}

.ngram-row {
    display: flex;
    align-items: center;
    justify-content: center;
}


.title {
    display: flex;
    padding: 5px 0 0 20px;
    box-shadow: 0px 24px 3px -24px rgb(202, 201, 190);
    align-items: center;
}


.box-title {
    font-family: inherit;
    flex-basis: 0;
    flex-grow: 1;
    font-size: 15px;
    font-weight: bold;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.content-container {
    flex-basis: 0;
    flex-grow: 1;
}

.token {
    padding: 10px;
    width: 150px;
}

.ngrambar {
    display: flex;
    justify-content: space-between;
    max-width: 125px;
    height: 20px;
    flex-grow: 1;
    border-radius: 0 10px 10px 0;
    background-color: rgb(216, 216, 216);
}

.ngram-count {
    display: flex;
    font-size: 10px;
    color: black;
    font-weight: bold;
    align-items: center;
    margin: 0 7px 0 7px;
}

.progress-bar {
    background-color: rgb(116, 14, 14);
    border-radius: 0 10px 10px 0;
}

.footer {
    display: flex;
    justify-content: center;
    margin: 20px
}