nav {
    display: flex;
    justify-content: center;
}

.pagination {
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
    list-style: none;
    margin: 0;
    padding: 0;
}

.page-item {
    text-align: center;
    border-radius: 5px;
    border: 1px solid rgb(187, 187, 187);
    transition: all .5s ease;

}

.page-item:hover {
    background-color: #e2e2e2;
}

.page-link {
    /* color: black; */
    text-decoration: none;
}

.pagination li {
    height: 30px;
    display: flex;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    width: 33px;
    align-items: center;
    gap: 10px;
}

.page-selected {
    font-weight: bold;
    color: rgb(153, 43, 43);
    border: 1px solid rgb(153, 43, 43);
}

@media screen and (max-width: 500px) {
    .pagination li {
        height: 17px;
        width: 23px;
    }
}