.container {
    grid-area: topicoverlaptable;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 0.2);
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px 0 20px;
    box-shadow: 0px 24px 3px -24px rgb(202, 201, 190);
}

.title-text {
    font-family: inherit;
    font-size: 15px;
    font-weight: bold;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.information-icon {
    cursor: pointer;
    font-size: 20px;
    color: #7c949c;
}

.popup-information {
    background: #f7f7f7;
    display: none;
    width: 280px;
    top: 25px;
    right: 0;
    z-index: 1;
    position: absolute;
    align-items: center;
    justify-content: center;
    border: 0.1px solid #cccccc;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 4px 2px 20px rgb(0 0 0 / 0.2);
}

.content {
    display: flex;
    flex-direction: column;
    min-height: 380px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 30px;
}

#overlap-table {
    width: 90%;
    text-align: center;
    border-collapse: collapse;
    table-layout: fixed;
}

#overlap-table thead {
    background-color: #ffffff;
    font-weight: bold !important;
    border-bottom: 1px solid #ddd;
    color: rgb(149 149 149);
}

#overlap-table thead tr th {
    font-weight: 900;
}

#overlap-table th,
#overlap-table td {
    font-weight: 100;
    padding: 10px;
}

.empty-table-wrapper {
    display: flex;
    flex-direction: column;
    font-family: inherit;
    text-align: center;
    justify-content: center;
    min-height: 250px;
    gap: 15px
}

.no-min-height {
    min-height: unset;
}

.footer {
    display: flex;
    justify-content: center;
    margin: 20px
}