.icon-container {
    width: fit-content;
    text-align: center;
    font-size: 22px;
    cursor: pointer;
    padding: 4px;
    color: #424746;
    transition: all .3s ease;

}

.icon-container:hover {
    background-color: #DDDDDD;
    border-radius: 5px;
}

@media screen and (max-width: 500px) {
    .icon-container {
        font-size: 15px;
    }
}