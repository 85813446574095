.container {
    grid-area: corpusstats;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 0.2);
    flex: 1;
    min-height: 328px;
}

.title {
    display: flex;
    padding: 5px 0 0 20px;
    box-shadow: 0px 24px 3px -24px rgb(202, 201, 190);
}

.title-text {
    font-family: inherit;
    flex-basis: 0;
    flex-grow: 1;
    font-size: 15px;
    font-weight: bold;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.content {
    display: flex;
    height: 100%;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

.percentage-content-wrapper {
    display: flex;
    width: 100%;
    gap: 50px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.topic-stats-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.single-chart {
    width: 25%;
    justify-content: space-around;
}

.circular-chart {
    display: block;
    margin: 10px auto;
    max-width: 80%;
    max-height: 250px;
}

.circle-bg {
    fill: none;
    stroke: #eee;
    stroke-width: 3.8;
}

.circle {
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
}

.orange .circle {
    stroke: #ff9f00;
}

.percentage {
    fill: #666;
    font-family: inherit;
    font-size: 0.5em;
    text-anchor: middle;
}

.classified-verbatims-wrapper,
.multiclassified-verbatims-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.percentage-explication-title {
    font-size: 14px;
    width: 170px;
    text-align: center;
}

@media screen and (max-width: 850px) {
    .greetings-text {
        font-size: 35px;
    }
}