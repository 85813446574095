.header {
    padding: 0 10%;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #424746;
    color: white;
    box-shadow: 0 1px 3px rgb(0 0 0 / 0.2);
}

.header h1 {
    color: black;
}

.header ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.logo-container {
    width: 100%;
    display: flex;
    flex-grow: 1;
    align-items: center;
}

.logo {
    width: 90px;
    height: 90px;
    cursor: pointer;
}

.environment-info-container {
    display: flex;
    padding: 5px 10px;
    border-radius: 10px;
    background-color: #d97931;
    text-transform: uppercase;
}

.content {
    display: flex;
    flex-basis: 40%;
    align-items: center;
    justify-content: center;
}

.user-info {
    display: flex;
    gap: 10px;
    width: 100%;
    flex-direction: row;
    align-items: center;
}

.user-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
}

.user-actions {
    display: flex;
    cursor: pointer;

}

/* Test with dropdown menu */

.dropdown-menu {
    position: relative;
    display: inline-block;
}

.user-info {
    cursor: pointer;
    padding: 10px;
    transition: all 0.5s ease;
    border-radius: 5px;
}

.user-info:hover {
    background-color: rgb(55, 55, 55);
    transition: all 0.5s ease;
}

.dropdown-content {
    display: none;
    width: 100%;
    top: 75px;
    border-radius: 5px;
    position: absolute;
    gap: 2px;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    flex-direction: column;
    align-items: center;
    padding: 15px;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #f1f1f1
}

.dropdown-item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: black;
    cursor: pointer;
    width: 100%;
    text-align: center;
    padding: 5px;
    transition: all .3s ease;
    border-radius: 5px;
}

.dropdown-item:hover {
    color: black !important;
    padding: 5px;
    background-color: rgb(223 223 223);
    transition: all .3s ease;
}