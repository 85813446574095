.enter-icon-container {
    display: block;
    margin: auto;
    cursor: pointer;
    width: fit-content;
    font-weight: bold;
    font-size: 18px;
    color: #3b3a3a;
    padding: 4px;
    transition: all .3s ease;
}

.enter-icon-container:hover {
    background-color: #DDDDDD;
    border-radius: 5px;
}

@media only screen and (max-width: 500px) {
    .enter-icon-container {
        font-size: 15px;
    }
}