.container {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 0.2);
    flex: 1;
}

.title {
    display: flex;
    padding: 5px 20px 0 20px;
    box-shadow: 0px 24px 3px -24px rgb(202, 201, 190);
}

.title-text {
    display: flex;
    align-items: center;
    font-family: inherit;
    flex-basis: 0;
    flex-grow: 1;
    font-size: 15px;
    font-weight: bold;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.content {
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

.table-selector-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.empty-table-wrapper {
    display: flex;
    flex-direction: column;
    font-family: inherit;
    text-align: center;
    justify-content: center;
    min-height: 250px;
    gap: 15px
}

.table-select-button {
    font-family: inherit;
    font-size: 11.5px;
    padding: 10px;
    background-color: #f7f7f7;
    border-radius: 5px;
    font-weight: 900;
    color: black;
    cursor: pointer;
    border: none;
    transition: all 0.4s ease;
}

.table-select-button:hover {
    background-color: #608da5;
    color: white;
    transition: all 0.4s ease;
}

.selected {
    background-color: #2981b1;
    color: white;
}


/* Some responsive stuff for actions buttons */

.action-button {
    background: none;
    color: black;
    padding: 5px;
    display: flex;
    justify-content: center;
    font-size: 22px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    transition: all .4s ease;
}

.action-button:hover {
    background-color: rgb(220, 220, 220);
    transition: all .4s ease;
}

.dropdown-menu {
    display: flex;
    position: relative;
    align-items: center;
}

.dropdown-content {
    width: 120px;
    right: 20px;
    display: none;
    border-radius: 10px;
    position: absolute;
    bottom: 30px;
    gap: 2px;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    flex-direction: column;
    align-items: center;
    padding: 15px;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #f1f1f1
}

.dropdown-item {
    color: black;
    cursor: pointer;
    padding: 5px;
    transition: all .3s ease;
    border-radius: 5px;
}

.dropdown-item:hover {
    color: white !important;
    padding: 5px;
    background-color: rgb(62, 62, 62);
    transition: all .3s ease;
}

.ellipsis-button {
    background: none;
    color: black;
    padding: 5px;
    display: flex;
    justify-content: center;
    font-size: 22px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    transition: all .4s ease;
}

@media screen and (max-width: 500px) {
    .table-select-button {
        padding: 5px;
        font-size: 8px;
    }
}