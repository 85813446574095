.blacklist-add-button {
    text-align: center;
    font-size: 22px;
    color: rgb(128, 1, 1);
    cursor: pointer;
    padding: 4px;
    transition: all .4s ease;
}

.blacklist-add-button:hover {
    background-color: #DDDDDD;
    border-radius: 5px;
}

@media screen and (max-width: 500px) {
    .blacklist-add-button {
        font-size: 15px;
    }
}