.create-version-wrapper {
    display: flex;
    width: 100%;
    margin-top: 50px;
    flex-direction: column;
    align-items: center;
}

.container {
    background: white;
    display: flex;
    width: 1018px;
    min-height: 300px;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 1px 2px rgb(0 0 0 / 0.2);
    margin: 10px;
    padding: 30px 20px;
    border-radius: 20px;
}

.creation-version-form {
    display: flex;
    flex-direction: column;
    gap: 5px
}

.title-content {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
}

.version-name-container {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 10px;
}

.create-version-image {
    width: 50px;
    height: 50px;
}

.version-name-container input[type=text] {
    font-family: inherit;
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid rgb(197, 197, 197);
}

.version-name-container input[type=text]:focus {
    outline: none !important;
    border: 1px solid #88a9d9;
    box-shadow: 0 0 2px #4d4d4d;
}

.toggle-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 11px;
}

.create-version-form-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex-grow: 1;
    min-height: 9rem;
}

.duplicate-versions-select-wrapper {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 5px 0 25px 0;
}

.version-select {
    border: 1px solid #b6b6b6;
    padding: 10px;
    border-radius: 5px;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
}

.version-select:focus {
    border: 1px solid #88a9d9;
    box-shadow: 0 0 2px #88a9d9;
}

.version-select:focus-visible {
    outline: none;
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 550px) {
    .version-name-container input[type=text] {
        font-size: 10px;
    }
}