.file-file-upload-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.upload-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.file-upload-form {
    display: block;
    clear: both;
    margin: 0 auto;
    width: 100%;
}

.input-msg-container {
    display: flex;
}

.upload-information-wrapper {
    display: flex;
    height: 250px;
    flex-direction: column;
    gap: 20px;
    transition: all 0.9s ease;
    color: #424746;
    border: 1px dashed #e7e7e7;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
}

.upload-file-icon {
    font-size: 50px;
    margin-bottom: 1rem;
    transition: all 0.2s ease-in-out;
}

.uplaod-information-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

#input-file {
    display: none;
}

.upload-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 0 20px;
}