.switch {
    display: inline-block;
    position: relative;
    width: 47px;
    height: 24px;
    cursor: pointer;
    overflow: hidden;
}

.switch input {
    position: absolute;
    top: -30px;
    top: -30px;
    width: 0;
    height: 0;
}

.switch input+span {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #a2a2a3;
    border-radius: 20px;
}

.switch input+span:before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 3px;
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 50%;
    transform: translateY(-50%);
    transition: all .3s;
}

.switch input:checked+span {
    background: #208d12;
}

.switch input:checked+span:before {
    left: 25px;
}