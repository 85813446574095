.goback-button {
    font-family: inherit;
    font-size: 20px;
    padding: 10px;
    background: none;
    border-radius: 5px;
    font-weight: 900;
    color: var(--goback-button-text-color);
    cursor: pointer;
    border: none;
    transition: all 0.4s ease;
}

.goback-button:hover {
    background-color: var(--goback-button-hover-color);
    transition: all 0.4s ease;
}