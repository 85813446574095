.keyword-container {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #e2e2e2;
    border-radius: 5px;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    gap: 5px;
}

.keyword-checkbox-wrapper {
    display: flex;
    margin: 5px;
    flex: 0 1 10%;
    min-height: 35px;
    justify-content: center;
    align-items: center;
}

.keyword-checkbox {
    width: 30px;
    height: 15px;
    cursor: pointer;
    text-align: center;
}

.keyword-item {
    display: flex;
    align-items: center;
    margin: 5px;
    min-height: 35px;
    flex-basis: 0;
    flex-grow: 1;
}

.keyword-label {
    display: flex;
    align-items: center;
    margin: 5px;
    min-height: 35px;
    flex: 0 1 20%;
    align-items: center;
    justify-content: center;
}

.keyword-type {
    text-align: center;
    font-size: 11px;
    background: rgb(125, 166, 232);
    padding: 8px;
    border-radius: 20px;
    width: 55px;
    text-transform: uppercase;
}

.match-type-selector {
    height: 30px;
    border-radius: 5px;
    text-align: center;
    font-family: inherit;
}

.token-name-edit-textfield {
    height: 25px;
    width: 25%;
    font-family: inherit;

}