.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.creation-project-form {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 5px
}

.title-content {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
}

.project-name-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
}

.create-project-image {
    width: 50px;
    height: 50px;
}

.project-name-container input[type=text] {
    font-family: inherit;
    padding: 12px 20px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid rgb(197, 197, 197);
}

.project-name-container input[type=text]:focus {
    outline: none !important;
    border: 1px solid #2684FF;
    box-shadow: 0 0 0 1px #2684FF;
}

.project-preprocess-config-wrapper {
    row-gap: 10px;
    column-gap: 25px;
    display: flex;
    flex-direction: column;
    align-content: center;
    max-height: 270px;
    flex-wrap: wrap;
}

.multi-select-wrapper {
    width: 50%;
}

.label {
    font-size: .75rem;
    font-weight: bold;
    line-height: 2;
}

.popup-description {
    background: #f7f7f7;
    display: none;
    width: 280px;
    top: 25px;
    z-index: 1;
    position: absolute;
    align-items: center;
    justify-content: center;
    border: 0.1px solid #cccccc;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 4px 2px 20px rgb(0 0 0 / 0.2);
}

.toggle-list-wrapper {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 11px;
}

.toggle-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 11px;
}

.description-icon {
    cursor: pointer;
    font-size: 20px;
    color: #7c949c;
}

.create-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    flex-grow: 1;
    min-height: 9rem;
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
}


@media screen and (max-width: 1000px) {
    .project-preprocess-config-wrapper {
        max-height: 300px;
    }
}

@media screen and (max-width: 715px) {
    .project-preprocess-config-wrapper {
        flex-wrap: nowrap;
        max-height: none;
    }
}

@media screen and (max-width: 550px) {
    .project-name-container input[type=text] {
        font-size: 10px;
    }
}