.topic-container {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #e2e2e2;
    border-radius: 5px;
    justify-content: space-around;
    align-items: center;
}

.topic-browser-header {
    display: flex;
    flex-direction: row;
    width: 70%;
    border-bottom: 1px solid #e2e2e2;
    border-radius: 5px;
    justify-content: space-around;
    align-items: center;
}

.icon-wrapper {
    display: flex;
    gap: 5px;
}

.topic-name {
    text-align: center;
}

.topic-labels {
    padding: 5px;
    flex-grow: 1;
}

.topic-detail {
    padding: 5px;
    flex-basis: 8%;
    text-align: center;
    transition: all .3s ease;
    cursor: pointer;
}

.topic-detail:hover {
    background-color: #DDDDDD;
    border-radius: 5px;
}

.topic-edit {
    padding: 5px;
    flex-basis: 10%;
    font-weight: bold;
    color: black;
    text-align: center;
    transition: all .3s ease;
}

.topic-edit:hover {
    background-color: #DDDDDD;
    border-radius: 5px;
}

.topic-delete {
    padding: 5px;
    flex-basis: 8%;
    text-align: center;
    transition: all .3s ease;
}

.custom-delete-button {
    cursor: pointer;
    font-size: 18px;
    color: brown;
    padding: 4px;
}

.custom-delete-button:hover {
    background-color: #DDDDDD;
    border-radius: 5px;
}

.label-container {
    text-align: center;
    font-size: 10px;
    width: fit-content;
    background: rgb(214 236 199);
    padding: 4px 8px;
    border-radius: 20px;
}

.topic-container input[type=text] {
    font-family: inherit;
    padding: 5px 20px;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid rgb(197, 197, 197);
}

.topic-container input[type=text]:focus {
    outline: none !important;
    border: 1px solid #ff9f00;
    box-shadow: 0 0 2px #4d4d4d;
}