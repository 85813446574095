.create-project-container {
    display: flex;
    width: 100%;
    margin-top: 50px;
    flex-direction: column;
    align-items: center;
}

.container {
    background: white;
    display: flex;
    width: 1018px;
    min-height: 300px;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 1px 2px rgb(0 0 0 / 0.2);
    margin: 10px;
    padding: 30px 20px;
    border-radius: 20px;
}

.stepper-container {
    display: flex;
    justify-content: center;
}

.section-container {
    width: 100%;
}

@media screen and (max-width: 1056px) {
    .container {
        width: 75%;
    }
}

@media screen and (max-width: 550px) {
    .container {
        font-size: 10px;
    }
}

@media screen and (max-width: 1060px) {
    .container {
        width: 75%;
    }
}