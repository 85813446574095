.container {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 0.2);
}

.title {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 20px 0 20px;
    box-shadow: 0px 24px 3px -24px rgb(202, 201, 190);
}

.title-text {
    font-family: inherit;
    flex-grow: 1;
    font-size: 15px;
    font-weight: bold;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.content {
    display: flex;
    flex-direction: column;
    flex-basis: 0;
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
}

.all-selected-checkbox {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.custom-delete-button {
    cursor: pointer;
    font-size: 22px;
    color: brown;
    padding: 4px;
}

.custom-delete-button:hover {
    background-color: #DDDDDD;
    border-radius: 5px;
}

.input-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-top: 5px;
}

.input-row {
    display: flex;
    align-items: center;
    width: 50%;
    gap: 10px;
}

.match-type-selector {
    height: 30px;
    border-radius: 5px;
    text-align: center;
    font-family: inherit;
}


input.match-type-input-text {
    width: 60%;
    border: 0;
    border-bottom: 1.5px solid grey;
    padding: 10px;
    flex-basis: 0;
    flex-grow: 1;
    font-family: inherit;
}

input.match-type-input-text[type=text]:focus {
    outline: none;
    border-bottom: 1.5px solid rgb(6, 78, 109);
    transition: all 0.3s ease;
}

.add-icon-wrapper {
    text-align: center;
    font-size: 22px;
    cursor: pointer;
}

.add-icon-wrapper:hover {
    color: rgb(1, 104, 2);
    transition: all 0.4s ease;
}

.footer {
    display: flex;
    justify-content: center;
    margin: 20px
}