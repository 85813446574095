.primary-button {
    font-family: inherit;
    font-size: 13.5px;
    padding: 10px;
    background-color: #464747;
    border-radius: 5px;
    font-weight: 900;
    color: white;
    cursor: pointer;
    border: none;
    transition: all 0.4s ease;
}

.primary-button:hover {
    background-color: #191a1b;
    transition: all 0.4s ease;
}

.disabled-button {
    font-family: inherit;
    font-size: 13.5px;
    padding: 10px;
    background-color: #464747;
    border-radius: 5px;
    font-weight: 900;
    color: white;
    cursor: no-drop;
}

@media screen and (max-width: 550px) {
    .primary-button {
        font-size: 10px;
    }
}