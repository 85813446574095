.action-buttons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}

.topic-view-container {
    display: flex;
    width: 1256px;
    flex-direction: column;
    gap: 10px;
    margin: 20px;
}

.multi-ngram-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 10px;
}

.action-buttons-left-side {
    display: flex;
}

.action-buttons-right-side {
    display: flex;
    gap: 5px;
}

@media only screen and (max-width: 1200px) {
    .topic-view-container {
        flex-basis: 90%;
    }
}

/* @media screen and (min-width: 200px) and (max-width: 640px) {
    .topic-view-container {
        flex-basis: 100%;
    }
} */

@media screen and (max-width: 480px) {
    .topic-view-container {
        width: 60%;
    }
}


@media only screen and (max-width: 1000px) {
    .multi-ngram-container {
        flex-direction: column;
    }
}