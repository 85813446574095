.container {
    grid-area: verbatim;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 0.2);
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px 0 20px;
    box-shadow: 0px 24px 3px -24px rgb(202, 201, 190);
}

.title-text {
    font-family: inherit;
    font-size: 15px;
    font-weight: bold;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.content {
    display: flex;
    flex-direction: column;
    min-height: 150px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 30px;
}

.table-container {
    width: 90%;
    padding: 0 30px;
    min-height: 250px;
    border-radius: 5px;
}

.empty-table-wrapper {
    display: flex;
    flex-direction: column;
    font-family: inherit;
    text-align: center;
    justify-content: center;
    min-height: 250px;
    gap: 15px
}

.switch-wrapper {
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap: 5px;
}

#project-table {
    border-collapse: collapse;
    width: 100%;
}

#project-table th {
    text-align: left;
    border-bottom: 0.5px solid #d8d8d8;
}

#project-table td,
#project-table th,
#project-table tbody tr th {
    padding: 5px;
}

#project-table tbody tr th {
    font-weight: 100;
}

@media only screen and (max-width: 500px) {
    #project-table {
        font-size: 11px;
    }
}