.tag-content {
    display: block;
    margin: auto;
    background-color: darkgray;
    width: fit-content;
    padding: 8px;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    font-size: 10px;
    text-transform: capitalize;
}

@media only screen and (max-width: 500px) {
    .tag-content {
        font-size: 9px;
        padding: 4px;
    }
}


@media screen and (max-width: 650px) {
    .tag-content {
        font-size: 8px;
        padding: 5px;
    }
}