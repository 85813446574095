.wrapper {
    display: flex;
    position: fixed;
    width: inherit;
    height: 100%;
    margin-top: -96px;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
    overflow: hidden;
}

.content {
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 30px;
    box-sizing: border-box;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 600px;
    height: 600px;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 24px 3px -24px rgb(202, 201, 190);
}