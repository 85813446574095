.container {
    grid-area: topicbrowser;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 0.2);
    min-height: 328px;
}

.title {
    display: flex;
    padding: 5px 0 0 20px;
    box-shadow: 0px 24px 3px -24px rgb(202, 201, 190);
}

.title-text {
    font-family: inherit;
    flex-basis: 0;
    flex-grow: 1;
    font-size: 15px;
    font-weight: bold;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.content {
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 20px;
}

.no-topic-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-basis: 0;
    flex-grow: 1;
}

.button-container {
    margin: 0 10px 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-topic-button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: rgb(114, 0, 0);
    border: none;
    color: white;
    cursor: pointer;
}

.footer {
    display: flex;
    justify-content: center;
    margin: 20px
}