.loading-button {
    font-family: inherit;
    font-size: 13.5px;
    padding: 10px;
    background-color: #434343;
    border-radius: 5px;
    font-weight: 900;
    color: white;
    border: none;
    transition: all .4s ease;
    pointer-events: none;
    cursor: not-allowed;
}