.container {
    display: flex;
    margin-top: 15px;
    width: 75%;
    max-width: 1018px;
    flex-direction: column;
    align-items: center;
}

.title-wrapper {
    display: flex;
    height: 2rem;
    justify-content: space-between;
    padding: 0px 0;
    margin: 15px 0 5px 0;
    width: 97%;
    align-items: center;
}

.title {
    font-size: 20px;
    font-weight: bold;
}

.action-buttons-wrapper {
    display: flex;
    gap: 10px;
}

.project-overview-container {
    background: white;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 250px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 0.2);
    margin: 10px;
    padding: 30px 0;
    border-radius: 20px;
}


.refresh-button-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.table-container {
    width: 90%;
    padding: 0 30px;
    min-height: 250px;
    border-radius: 5px;
}

.empty-table-wrapper {
    display: flex;
    flex-direction: column;
    font-family: inherit;
    text-align: center;
    justify-content: center;
    min-height: 250px;
    gap: 15px
}

#project-table {
    border-collapse: collapse;
    width: 100%;
}

#project-table thead tr {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    color: rgb(149 149 149);
    font-weight: bold;
    border-bottom: 1px solid #ddd;
}

#project-table td,
#project-table th,
#project-table tbody tr th {
    padding: 5px;
    text-align: center;
}

#project-table tbody tr th {
    font-weight: 100;
}

@media only screen and (max-width: 500px) {
    .container {
        width: 95%;
    }

    #project-table {
        font-size: 11px;
    }
}

@media screen and (max-width: 650px) {
    .project-overview-container {
        font-size: 10px;
    }
}

@media screen and (max-width: 725px) {
    #project-table {
        font-size: 10px;
    }
}

@media screen and (max-width: 500px) {
    #project-table {
        font-size: 9px;
    }
}