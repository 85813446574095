.container {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 0.2);
    flex-basis: 0;
    flex-grow: 1;
    min-height: 328px;
}

.content-container {
    flex-basis: 0;
    flex-grow: 1;
}

.ngram-row {
    display: flex;
    align-items: center;
    justify-content: center;
}

.title {
    display: flex;
    padding: 5px 0 0 20px;
    box-shadow: 0px 24px 3px -24px rgb(202, 201, 190);
    align-items: center;
}


.box-title {
    font-family: inherit;
    flex-basis: 0;
    flex-grow: 1;
    font-size: 15px;
    font-weight: bold;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.token {
    padding: 10px;
    width: 150px;
}

.attributed-topic-container {
    width: 50px;
}

.attributed-topic {
    text-align: center;
    font-size: 10px;
    background: antiquewhite;
    padding: 5px;
    border-radius: 20px;
}

.ngrambar {
    display: flex;
    justify-content: space-between;
    max-width: 150px;
    height: 20px;
    flex-grow: 1;
    border-radius: 10px;
    background-color: rgb(216, 216, 216);
}

.ngram-count {
    display: flex;
    font-size: 10px;
    color: black;
    font-weight: bold;
    align-items: center;
    margin: 0 7px 0 7px;
}

.progress-bar {
    background-color: rgb(116, 14, 14);
    /* width: 150px; */
    border-radius: 10px;
}

.button-container {
    flex-basis: 10%;
    display: flex;
    justify-content: center;
}

.custom-add-button {
    text-align: center;
    font-size: 22px;
    color: green;
    cursor: pointer;
    padding: 4px;
    transition: all .4s ease;
}

.custom-add-button:hover {
    background-color: #DDDDDD;
    border-radius: 5px;
}

.footer {
    display: flex;
    justify-content: center;
    margin: 20px
}

@media only screen and (max-width: 1000px) {
    .container {
        min-height: unset;
    }
}

@media screen and (max-width: 500px) {
    .custom-add-button {
        font-size: 15px;
    }
}