.regex-builder-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 30px;
    min-height: 150px;
    padding: 30px;
}

.input-wrapper {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.regex-builder-results-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.regex-suggestion-row {
    display: flex;
    flex-direction: row;
    width: 75%;
    border-bottom: 1px solid #ebebeb;
    justify-content: space-between;
    align-items: center;
}

.regex-information {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.action-buttons-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

#regex-query-input {
    font-family: inherit;
    padding: 10px 20px;
    width: 75%;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid rgb(197, 197, 197);
}

#regex-query-input:focus {
    outline: none !important;
    border: 1px solid #ff9f00;
    box-shadow: 0 0 2px #4d4d4d;
}