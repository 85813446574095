.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.not-found-image-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.error-message-wrapper {
    display: flex;
    flex-direction: row;
    padding: 20px;
    min-height: 250px;
    min-width: 250px;
    margin-top: 150px;

}

.message-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.not-found-error-code {
    font-weight: bold;
    text-align: center;
    font-size: 100px;
    color: #ae0000;
}

.not-found-message {
    font-size: 30px;
    text-align: center;
}

.go-back-button {
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 880px) {
    .not-found-gif {
        width: 200px;
        height: 200px;
    }

    .not-found-error-code {
        font-size: 50px;
    }

    .not-found-message {
        font-size: 20px;
    }
}

@media screen and (max-width: 550px) {
    .not-found-gif {
        width: 100px;
        height: 100px;
    }

    .not-found-error-code {
        font-size: 30px;
    }

    .not-found-message {
        font-size: 15px;
    }
}