.del-icon-container {
    display: block;
    margin: auto;
    cursor: pointer;
    width: fit-content;
    font-size: 22px;
    color: brown;
    padding: 4px;
    transition: all .3s ease;
}

.del-icon-container:hover {
    background-color: #DDDDDD;
    border-radius: 5px;
}

@media only screen and (max-width: 500px) {
    .del-icon-container {
        font-size: 15px;
    }
}

@media screen and (max-width: 650px) {
    .del-icon-container {
        font-size: 15px;
    }
}