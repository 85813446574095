.circle-chart {
    justify-content: space-around;
}

.circle-bg {
    fill: none;
    stroke: #eee;
    stroke-width: 3.8;
}

.circle {
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
}

.percentage-text {
    fill: #666;
    font-family: inherit;
    font-weight: bold;
    text-anchor: middle;
}