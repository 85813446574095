.empty-table-wrapper {
    display: flex;
    flex-direction: column;
    font-family: inherit;
    text-align: center;
    justify-content: center;
    min-height: 250px;
    gap: 15px
}

.table-container {
    width: 100%;
    margin-top: 25px;
    padding: 0 30px;
    min-height: 250px;
    border-radius: 5px;
}

.icon-wrapper {
    display: flex;
    justify-content: center;
}

#assistant-suggestion-table {
    border-collapse: collapse;
    width: 100%;
}

#assistant-suggestion-table thead th,
#assistant-suggestion-table tbody th {
    text-align: center;
}

#assistant-suggestion-table th {
    text-align: left;
    border-bottom: 0.5px solid #d8d8d8;
}

#assistant-suggestion-table td,
#assistant-suggestion-table th,
#assistant-suggestion-table tbody tr th {
    padding: 5px;
}

#assistant-suggestion-table tbody tr th {
    font-weight: 100;
}

@media only screen and (max-width: 500px) {
    .container {
        width: 95%;
    }

    #assistant-suggestion-table {
        font-size: 11px;
    }
}